import { FetcherWithComponents } from '@remix-run/react'
import { Button, Loader, TextInput } from '~/design-system'

function ForgotPassword({
  fetcher,
  changeToSignInForm,
  email,
  setEmail,
}: {
  fetcher: FetcherWithComponents<{ ok: boolean; errorMessage: string }>
  changeToSignInForm: () => void
  email: string
  setEmail: (value: string) => void
}) {
  const isSubmitting = fetcher.state != 'idle'
  const errorMessage = fetcher.data?.errorMessage
  const resetSent = !!fetcher.data?.ok

  function ResetSent() {
    return (
      <div className="flex flex-col justify-center items-center p-8 lg:p-12 gap-y-2 w-full">
        <h1 className="text-xl text-center">Password reset email sent!</h1>
        <p className="body-sm text-neutral-50-secondary-text text-center mb-2">
          An email with a password reset link has been sent to {email}. Please
          check your inbox and follow the instructions in the email to reset
          your password. If you don't see the email in your inbox, kindly check
          your spam or junk folder.
        </p>
        <Button fullWidth onClick={() => changeToSignInForm()}>
          <p className="title-sm">Reset to Login</p>
        </Button>
      </div>
    )
  }

  function ResetPending() {
    return (
      <div className="flex flex-col justify-center items-center p-8 lg:p-12 gap-y-2 w-full">
        {isSubmitting && <Loader />}
        <h1 className="body-xl text-neutral-60-primary-text">Password reset</h1>
        <h3 className="body-md text-neutral-50-secondary-text">
          No worries, we'll send you reset instructions.
        </h3>
        <fetcher.Form
          method="post"
          action="/api/reset-password"
          className="flex flex-col gap-y-6 w-full mt-2"
        >
          <TextInput
            label="Email"
            size="small"
            required
            name="email"
            type="email"
            data-testid="login-email-input"
            value={email}
            onChange={(value) => setEmail(value)}
          />
          {errorMessage && (
            <p className="text-red-500 body-sm text-center">{errorMessage}</p>
          )}
          <a
            role="button"
            className="text-xs text-neutral-50-secondary-text underline self-end"
            onClick={() => changeToSignInForm()}
          >
            Or return to Sign in
          </a>
          <Button fullWidth submit disabled={isSubmitting}>
            Reset Password
          </Button>
        </fetcher.Form>
      </div>
    )
  }

  return resetSent ? <ResetSent /> : <ResetPending />
}

export default ForgotPassword
