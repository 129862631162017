import React from 'react'
import { Link } from '@remix-run/react'
import circleMedicalLogo from './assets/circle-medical-logo.svg'
import Routes from '~/common/routes'

const Logo: React.FC = () => {
  return (
    <Link to={Routes.HOME} className="flex items-center">
      <img
        src={circleMedicalLogo}
        className="mr-3 h-logo"
        alt="Go to Circle Medical home"
      />
    </Link>
  )
}

export default Logo
