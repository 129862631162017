import { useState, useRef, useEffect } from 'react'
import { useFetchers, useNavigation } from '@remix-run/react'
import clsx from 'clsx'

function GlobalLoader() {
  const navigation = useNavigation()
  const fetchers = useFetchers()
  const ref = useRef<HTMLDivElement>(null)
  const [animationComplete, setAnimationComplete] = useState(false)

  const isIdle =
    navigation.state === 'idle' ||
    fetchers.some(({ state }) => state === 'idle')
  const isSubmitting =
    navigation.state === 'submitting' ||
    fetchers.some(({ state }) => state === 'submitting')
  const isLoading =
    navigation.state === 'loading' ||
    fetchers.some(({ state }) => state === 'loading')

  useEffect(() => {
    if (!ref.current) return
    if (!isIdle) setAnimationComplete(false)

    const animations = ref.current
      .getAnimations()
      .map(({ finished }) => finished)
    Promise.allSettled(animations).then(
      () => isIdle && setAnimationComplete(true),
    )
  }, [isIdle])

  return (
    <div
      role="progressbar"
      aria-hidden={isIdle}
      aria-valuetext={isIdle ? undefined : 'Loading'}
      className="fixed inset-x-0 top-0 left-0 z-50 h-0.5"
    >
      <div
        ref={ref}
        className={clsx('h-full bg-blue-500 transition-all ease-in-out', {
          'opacity-0 w-0 duration-0': isIdle && animationComplete,
          'w-2/12': isSubmitting,
          'w-8/12': isLoading,
          'w-full duration-100': isIdle && !animationComplete,
          'duration-500': isSubmitting || isLoading,
          'opacity-100':
            (isIdle && !animationComplete) || isLoading || isSubmitting,
        })}
      />
    </div>
  )
}

export { GlobalLoader }
