import Routes from '~/common/routes'
import { Button } from '~/design-system'
import { getAppGlobals } from '~/global-data'
import warning from './assets/warning.svg'
import deleteSessionCookie from '~/lib/cookies/deleteSessionCookie'

export default function ErrorFallback() {
  const { COOKIE_DOMAIN } = getAppGlobals()

  function forceLogout() {
    deleteSessionCookie(COOKIE_DOMAIN)
  }

  return (
    <div className="flex justify-center">
      <main className="max-w-modal-container-mobile sm:max-w-lg h-screen flex flex-col justify-center items-center">
        <img src={warning} className="w-16 h-16" />
        <h1 className="text-center title-xl mt-8">Something went wrong</h1>
        <p className="body-lg text-center text-neutral-50-secondary-text mt-4">
          Try again later or contact our support team.
        </p>
        <div className="flex mt-8 w-56">
          <Button
            fullWidth
            href={Routes.LOGIN}
            onClick={forceLogout}
            size="medium"
          >
            Go back
          </Button>
        </div>
      </main>
    </div>
  )
}
