import { useState, useEffect } from 'react'
import * as Sentry from '@sentry/remix'
import type { FeedbackModalIntegration } from '@sentry/core'

type FeedbackDialog = ReturnType<FeedbackModalIntegration['createDialog']>

const useSentryFeedbackForm = () => {
  const [feedbackWidget, setFeedbackWidget] = useState<FeedbackDialog>()
  const [isFeedbackWidgetVisible, setIsFeedbackWidgetVisible] = useState(false)

  useEffect(() => {
    const feedback = Sentry.getFeedback()

    feedback
      ?.createForm({
        onFormClose: () => setIsFeedbackWidgetVisible(false),
        onFormSubmitted: () => setIsFeedbackWidgetVisible(false),
      })
      .then((widget) => {
        setFeedbackWidget(widget)
        widget.appendToDom()
      })
  }, [])

  if (isFeedbackWidgetVisible) {
    feedbackWidget?.open()
  } else {
    feedbackWidget?.close()
  }

  return { setIsFeedbackWidgetVisible }
}

export default useSentryFeedbackForm
