import type User from '../business/user'
import { useEffect, useRef } from 'react'

export const useUserLoggedIn = (
  callback: () => void,
  user: User | undefined,
) => {
  const wasLoggedIn = useRef(user !== undefined)

  useEffect(() => {
    if (!wasLoggedIn.current && user) {
      callback()
    }
  }, [user])
}
