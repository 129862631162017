import { useEffect, useState } from 'react'
import { getSessionId, load, page, type WindowAnalytics } from './analytics'
import { useLocation, useMatches } from '@remix-run/react'
import { getAppGlobals } from '~/global-data'
import * as sessionReplay from '@amplitude/session-replay-browser'
import * as Sentry from '@sentry/remix'

export type AnalyticsInitProps = {
  analyticsWriteKey: string
  analyticsDataPlaneUrl: string
}

type RouteMatchWithHandle = {
  handle?: {
    pageName?: string
  }
  pathname: string
}

export function useInitializeAnalytics({
  analyticsWriteKey,
  analyticsDataPlaneUrl,
}: AnalyticsInitProps) {
  const sessionId = getSessionId()
  useSetupGTM()
  useEffect(() => {
    load(analyticsWriteKey, analyticsDataPlaneUrl).catch((error: unknown) => {
      Sentry.captureException(error)
    })
  }, [analyticsWriteKey, analyticsDataPlaneUrl])

  useEffect(() => {
    if (sessionId) {
      sessionReplay.setSessionId(sessionId)
    }
  }, [sessionId])
}

export function useTrackPageView() {
  const location = useLocation()
  const matches = useMatches() as RouteMatchWithHandle[]

  useEffect(() => {
    const match = matches.find((m) => m.pathname === location.pathname)

    const pageName = match?.handle?.pageName || `${location.pathname}`
    page(pageName)
  }, [location.pathname])
}

const useSetupGTM = () => {
  const [initialized, setInitialized] = useState(false)
  const { GTM_MEASURE_ID } = getAppGlobals()
  useEffect(() => {
    if (GTM_MEASURE_ID && !initialized) {
      // autogenerated GTM script
      ;(function (
        window: WindowAnalytics,
        document: Document,
        dataLayerName: string,
        gtmId: string,
      ) {
        window[dataLayerName] = window[dataLayerName] || []
        window[dataLayerName].push({
          'gtm.start': new Date().getTime(),
          event: 'gtm.js',
        })
        const firstScriptTag = document.getElementsByTagName('script')[0]
        const element = document.createElement<'script'>('script')
        const dl = dataLayerName != 'dataLayer' ? '&l=' + dataLayerName : ''
        element.async = true
        element.src = 'https://www.googletagmanager.com/gtm.js?id=' + gtmId + dl
        firstScriptTag.parentNode?.insertBefore(element, firstScriptTag)
      })(
        window as unknown as WindowAnalytics,
        document,
        'dataLayer',
        GTM_MEASURE_ID,
      )
      setInitialized(true)
    }
  }, [])
}
