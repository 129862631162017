import { isRouteErrorResponse } from '@remix-run/react'

const UNEXPECTED_ERROR = 'An unexpected error has occurred'
const ACCESS_DENIED_ERROR = 'Access denied'

class FailedRequestError extends Error {
  response: Response

  constructor(message: string, response: Response) {
    super(message)
    this.response = response
  }
}

function addSearchParams(
  url: URL,
  searchParams?: Record<string, string> | [string, string][],
): void {
  if (searchParams) {
    const iterable = Array.isArray(searchParams)
      ? searchParams
      : Object.entries(searchParams)
    for (const [key, value] of iterable) {
      url.searchParams.append(key, value)
    }
  }
}

function getAdminBaseUrl(): string {
  const { ADMIN_BASE_URL } = process.env
  if (!ADMIN_BASE_URL)
    throw new Error('ADMIN_BASE_URL env variable is required')

  return ADMIN_BASE_URL
}

async function handleFailedRequest(response: Response): Promise<never> {
  const contentType = response.headers.get('Content-Type')

  if (!contentType?.includes('application/json')) {
    throw new FailedRequestError(UNEXPECTED_ERROR, response)
  }
  const { message: errorMessage } = await response.json()

  if (response.status === 401 && errorMessage === ACCESS_DENIED_ERROR) {
    throw new Response(ACCESS_DENIED_ERROR, { status: 401 })
  }

  throw new FailedRequestError(errorMessage ?? UNEXPECTED_ERROR, response)
}

function isAccessDeniedError(error: unknown): boolean {
  return (
    isRouteErrorResponse(error) &&
    error.status === 401 &&
    error.data === ACCESS_DENIED_ERROR
  )
}

export {
  FailedRequestError,
  addSearchParams,
  getAdminBaseUrl,
  handleFailedRequest,
  UNEXPECTED_ERROR,
  isAccessDeniedError,
  ACCESS_DENIED_ERROR,
}
