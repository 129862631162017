import { useFetcher } from '@remix-run/react'
import { useState } from 'react'
import { Modal } from '~/design-system'
import SignIn from '../../booking/components/SignIn'
import ForgotPassword from '../../booking/components/ForgotPassword'

function LoginModal({
  isOpen,
  closeModal,
}: {
  isOpen: boolean
  closeModal: () => void
}) {
  const signInFetcher = useFetcher<{ errorMessage: string }>()
  const forgotPasswordFetcher = useFetcher<{
    ok: boolean
    errorMessage: string
  }>()
  const [isSignIn, setIsSignIn] = useState(true)
  const [email, setEmail] = useState<string>('')

  const onClose = () => {
    closeModal()
    setEmail('')
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="flex justify-center items-center w-modal-container-mobile lg:w-medium-modal lg:max-w-page-container">
        {isSignIn ? (
          <SignIn
            fetcher={signInFetcher}
            changeToForgotPasswordForm={() => setIsSignIn(false)}
            closeModal={closeModal}
            email={email}
            setEmail={setEmail}
          />
        ) : (
          <ForgotPassword
            fetcher={forgotPasswordFetcher}
            changeToSignInForm={() => setIsSignIn(true)}
            email={email}
            setEmail={setEmail}
          />
        )}
      </div>
    </Modal>
  )
}

export default LoginModal
