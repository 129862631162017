import { NavLink, useLocation, useNavigate } from '@remix-run/react'
import clsx from 'clsx'
import Routes from '~/common/routes'
import chat from './assets/chat.svg'
import frontArrow from './assets/frontArrow.svg'
import { Conversation } from '~/domains/secureCareInbox'
import DeferredSkeleton from '../DeferredSkeleton'

const BackToHome = () => {
  const navigate = useNavigate()

  const goBack = () => {
    navigate(-1)
  }
  return (
    <button
      onClick={goBack}
      className={clsx(
        'rounded-full border-1 mr-2 cursor-pointer relative',
        'px-2 py-0.5 bg-primary-0 border-primary-10 hover:border-primary-30-default',
      )}
    >
      <div className="text-primary-30-default text-xs flex gap-1 items-center justify-center">
        <img src={frontArrow} className="h-3 w-3 rotate-180" />
        <span>Back</span>
      </div>
    </button>
  )
}

const InboxChat = ({
  conversations,
}: {
  conversations: Promise<Conversation[]>
}) => (
  <NavLink
    to={Routes.SECURE_CARE_INBOX}
    className={clsx(
      'rounded-full border-1 mr-2 hover:cursor-pointer relative',
      'p-2 bg-neutral-10-background border-neutral-20-borders-dividers hover:border-primary-20',
    )}
  >
    <DeferredSkeleton
      promise={conversations as Promise<Conversation[]>}
      errorElement={<></>}
      handleNotFound={false}
    >
      {(conversations) => {
        const unreadConversationsCount = conversations.filter(
          (conversation) => conversation.hasUnreadMessages,
        ).length
        return (
          <>
            <img src={chat} className="h-4 w-4" alt="Chat Icon" />
            {unreadConversationsCount > 0 && (
              <div className="absolute -top-1 -right-1 bg-danger-30-primary text-white body-xs rounded-full min-w-5 h-5 px-2 flex items-center justify-center">
                {unreadConversationsCount < 99
                  ? unreadConversationsCount
                  : '99+'}
              </div>
            )}
          </>
        )
      }}
    </DeferredSkeleton>
  </NavLink>
)

const ChatButton = ({
  conversations,
}: {
  conversations: Promise<Conversation[]>
}) => {
  const location = useLocation()
  const isSecureCareInboxChatRoute = location.pathname.includes(
    Routes.SECURE_CARE_INBOX,
  )

  return isSecureCareInboxChatRoute ? (
    <BackToHome />
  ) : (
    <InboxChat conversations={conversations} />
  )
}

export default ChatButton
