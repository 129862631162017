import { useEffect, useRef } from 'react'
import { IntercomAuth } from '~/domains/accounts/server'
import { useLocation } from '@remix-run/react'
import Routes from '~/common/routes'

interface UseIntercomProps {
  intercomAuth?: Promise<IntercomAuth | null>
  intercomApiBase: string
  intercomAppId: string
}

export const useIntercom = ({
  intercomAuth,
  intercomApiBase,
  intercomAppId,
}: UseIntercomProps) => {
  const intercomControl = useRef<{
    initialized: boolean
    update?: (_args: { hide_default_launcher: boolean }) => void
    shutdown?: () => void
  }>({
    initialized: false,
  })
  const { pathname } = useLocation()

  function shutdownIntercom() {
    intercomControl.current.shutdown?.()
    intercomControl.current = { initialized: false }
  }

  async function initializeIntercom() {
    const {
      default: Intercom,
      update,
      shutdown,
    } = await import('@intercom/messenger-js-sdk')
    const { intercomExternalId, hmacDigest } = (await intercomAuth) || {}

    if (!intercomExternalId || !hmacDigest) return

    Intercom({
      api_base: intercomApiBase,
      app_id: intercomAppId,
      user_id: intercomExternalId,
      user_hash: hmacDigest,
    })
    intercomControl.current = { initialized: true, update, shutdown }
  }

  function updateVisibility() {
    if (!intercomControl.current.initialized) return

    if (pathname.startsWith(Routes.SECURE_CARE_INBOX)) {
      intercomControl.current.update?.({ hide_default_launcher: true })
    } else {
      intercomControl.current.update?.({ hide_default_launcher: false })
    }
  }

  useEffect(() => {
    if (intercomAuth && !intercomControl.current.initialized) {
      initializeIntercom().then(() => updateVisibility())
    } else if (!intercomAuth && intercomControl.current.initialized) {
      shutdownIntercom()
    }
  }, [intercomAuth])

  useEffect(() => {
    updateVisibility()
  }, [pathname])

  return { isIntercomInitialized: intercomControl.current.initialized }
}
