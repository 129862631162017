import { Experiment, Result } from '@growthbook/growthbook-react'
import TrackingEvent from '../analytics/TrackingEvent'

export class ExperimentViewed extends TrackingEvent {
  constructor(
    private experiment: Experiment<string>,
    private result: Result<string>,
  ) {
    super('Experiment Viewed')
  }

  public properties(): Record<string, string> {
    return {
      experiment_id: this.experiment.key,
      variation_name: this.result.value,
      variation_id: this.result.key,
    }
  }
}
