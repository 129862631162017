import { useEffect, useRef } from 'react'
import { GrowthBook } from '@growthbook/growthbook-react'
import * as Sentry from '@sentry/remix'
import { track } from '~/lib/analytics'
import { ExperimentViewed } from './experimentViewed'
import { Features } from './featuresStore.server'
import { TargetingAttributes } from './targetingAttributes.server'
import { getAppGlobals } from '~/global-data'

export const useSetupExperimentation = (
  features: Features,
  attributes: TargetingAttributes,
) => {
  const { APP_ENV } = getAppGlobals()
  const growthBook = useRef<GrowthBook | null>(null)

  if (growthBook.current === null) {
    growthBook.current = new GrowthBook({
      attributes,
      enableDevMode: APP_ENV !== 'production',
      trackingCallback: (experiment, result) => {
        track(new ExperimentViewed(experiment, result))
        Sentry.addBreadcrumb({
          message: 'Experiment Viewed',
          data: {
            experiment_id: experiment.key,
            variation_name: result.value,
            variation_id: result.key,
          },
        })
      },
    }).initSync({
      payload: {
        features,
      },
    })
  }

  useEffect(() => {
    growthBook.current?.setPayload({ features })
  }, [features])

  useEffect(() => {
    growthBook.current?.setAttributes(attributes)
  }, [attributes])

  return {
    growthBook: growthBook.current,
  }
}
